import { inject } from 'vue';

import { MixpanelPluginInjectionKey } from 'sharedApp/plugins/tracking/mixpanel/mixpanel-plugin.ts';

export default function useMixpanel() {
  const mixpanelUnsetError = new Error(
    'useMixpanel requires mixpanel-plugin to be installed in the Vue app',
  );
  const mixpanel = inject(MixpanelPluginInjectionKey);

  if (typeof mixpanel === 'undefined') throw mixpanelUnsetError;

  return mixpanel;
}
